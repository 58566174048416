import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  editTrx,
  getByTxId,
  reset,
} from "../features/transaction/transactionSlice";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

const EditTrxModal = ({ isOpen, onClose, onEdit, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialState = {
    to: "",
    amount: "",
    date: new Date(),
    status: "",
    // type: "",
    // code: "",
  };
  const [editedData, setEditedData] = useState(initialState);

  const { singleTx, edited, editLoad } = useSelector(
    (state) => state.transaction
  );

  useEffect(() => {
    if (id) {
      dispatch(getByTxId(id));
    }
  }, [id, dispatch]);

  // console.log(singleTx);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setEditedData((prevData) => ({
      ...prevData,
      date,
    }));
  };

  const handleSubmit = () => {
    // Perform the actual edit logic using onEdit and pass the editedData
    console.log(editedData);

    dispatch(editTrx({ id, editedData }));
    // onEdit(editedData);
    // Close the modal
  };

  useEffect(() => {
    if (edited) {
      onClose();
      dispatch(reset());
      window.location.reload();
    }
  }, [edited, dispatch, onClose, navigate]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Transaction</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <select
              className="status-comp"
              name="status"
              value={editedData.status}
              onChange={handleInputChange}
            >
              <option value="">select status</option>
              <option value="Pending 0/3">Pending 0/3</option>
              <option value="Pending 1/3">Pending 1/3</option>
              <option value="Pending 2/3">Pending 2/3</option>
              <option value="Pending 3/3">Pending 3/3</option>
              <option value="Confirmed">Confirmed</option>
            </select>
            {/* <Input /> */}
          </FormControl>

          <FormControl>
            <FormLabel>Date</FormLabel>

            <div className="status-comp2">
              <DatePicker
                name="date"
                selected={editedData.date}
                onChange={handleDateChange}
                className="status-comp"
              />
            </div>
          </FormControl>
          <FormControl>
            <FormLabel>Receiver</FormLabel>

            <Input
              name="to"
              value={editedData.to}
              onChange={handleInputChange}
              placeholder={singleTx && singleTx.tx.to}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Amount</FormLabel>

            <Input
              name="amount"
              value={editedData.amount}
              onChange={handleInputChange}
              placeholder={singleTx && singleTx.tx.amount}
            />
          </FormControl>
          {/* <FormControl>
            <FormLabel>Type</FormLabel>

            <Input
              name="type"
              value={editedData.type}
              onChange={handleInputChange}
              placeholder={singleTx && singleTx.tx.type}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Token</FormLabel>

            <Input
              name="code"
              value={editedData.code}
              onChange={handleInputChange}
              placeholder={singleTx && singleTx.tx.code}
            />
          </FormControl> */}
          {/* Add other form fields for date, email, username, etc. */}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            {editLoad ? "Saving Changes..." : "Save Changes"}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditTrxModal;
