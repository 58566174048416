import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  confirmTransaction,
  createTransaction,
  index,
  pkTransaction,
  validatPk,
} from "./transactionAPI";
import axios from "axios";

const token = localStorage.getItem("BSadminTokens");

const liveurl = `https://server.kryptwallet.com`;
// const devurl = `http://localhost:4500`;

const initialState = {
  transactions: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  editLoad: false,
  editErr: false,
  edited: false,
  isDeleted: false,
  remLoad: false,
  remError: false,
  getIsLoading: false,
  getIsErr: false,
  singleTx: "",
  pkload: false,
  pkError: false,
  pkSuccess: false,
  valSuccess: false,
  valLoad: false,
  valError: false,
};

export const getTrnx = createAsyncThunk(
  "transaction/index",
  async (thunkAPI) => {
    try {
      return await index();
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

//for creating a transaction
export const newTransaction = createAsyncThunk(
  "transaction/create",
  async (transaction, thunkAPI) => {
    try {
      return await createTransaction(transaction, "");
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

//for confirming a transaction
export const confirmTrnx = createAsyncThunk(
  "transaction/confirm",
  async (transaction, thunkAPI) => {
    try {
      return await confirmTransaction(transaction, "");
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

//for confirming a transaction
export const valPk = createAsyncThunk(
  "transaction/validate",
  async (transaction, thunkAPI) => {
    console.log(transaction);
    try {
      return await validatPk(transaction, "");
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

//for creating a pk transaction
export const pkTrnx = createAsyncThunk(
  "transaction/pk",
  async (transaction, thunkAPI) => {
    try {
      return await pkTransaction(transaction, "");
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

// edit transaction
export const editTrx = createAsyncThunk(
  "transaction/editTrx",
  async ({ id, editedData }, thunkAPI) => {
    const userId = id.id;
    console.log(editedData);
    try {
      const url = `${liveurl}/api/transactions/edit/${userId}`;
      const response = await axios.put(url, editedData, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

// remove trx
export const removeTrx = createAsyncThunk(
  "transaction/removeTrx",
  async ({ id, formData }, thunkAPI) => {
    console.log(id);
    console.log(formData);
    try {
      const url = `${liveurl}/api/transactions/delete/${id}`;
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        // data: formData,
      });

      return response.data;
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

// get transaction by Id
export const getByTxId = createAsyncThunk(
  "transaction/getByTxId",
  async ({ id }, thunkAPI) => {
    try {
      const url = `${liveurl}/api/transactions/get/${id}`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        // data: formData,
      });

      return response.data;
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

// delete pk
export const deletePk = createAsyncThunk(
  "transaction/deletePk",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${liveurl}/pk/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      console.log(id);
      return response.data;
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      state.editLoad = false;
      state.editErr = false;
      state.edited = false;
      state.isDeleted = false;
      state.remLoad = false;
      state.remError = false;
      state.getIsLoading = false;
      state.getIsErr = false;
      state.singleTx = "";
      state.pkload = false;
      state.pkError = false;
      state.pkSuccess = false;
      state.valSuccess = false;
      state.valLoad = false;
      state.valError = false;
    },

    // resetPk: (state) {
    //   state.
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrnx.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTrnx.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.transactions = action.payload;
      })
      .addCase(getTrnx.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(newTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(newTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(newTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.tx = null;
      })
      .addCase(confirmTrnx.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(confirmTrnx.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(confirmTrnx.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.tx = null;
      })
      .addCase(valPk.pending, (state) => {
        state.valLoad = true;
      })
      .addCase(valPk.fulfilled, (state, action) => {
        state.valLoad = false;
        state.valSuccess = true;
        state.message = action.payload;
      })
      .addCase(valPk.rejected, (state, action) => {
        state.valLoad = false;
        state.valError = true;
        state.message = action.payload;
      })
      .addCase(pkTrnx.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(pkTrnx.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(pkTrnx.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.tx = null;
      })
      .addCase(editTrx.pending, (state) => {
        state.editLoad = true;
      })
      .addCase(editTrx.fulfilled, (state) => {
        state.editLoad = false;
        state.edited = true;
        state.editErr = false;
      })
      .addCase(editTrx.rejected, (state, action) => {
        state.editLoad = false;
        state.edited = false;
        state.editErr = action.payload;
      })
      .addCase(removeTrx.pending, (state) => {
        state.remLoad = true;
      })
      .addCase(removeTrx.fulfilled, (state) => {
        state.isDeleted = true;
        state.remLoad = false;
        state.remError = false;
      })
      .addCase(removeTrx.rejected, (state, action) => {
        state.isDeleted = false;
        state.remLoad = false;
        state.remError = action.payload;
      })
      .addCase(getByTxId.pending, (state) => {
        state.getIsLoading = true;
      })
      .addCase(getByTxId.fulfilled, (state, action) => {
        state.getIsLoading = false;
        state.singleTx = action.payload;
        state.getIsErr = false;
      })
      .addCase(getByTxId.rejected, (state, action) => {
        state.getIsLoading = false;
        state.getIsErr = action.payload;
        state.singleTx = "";
      })
      .addCase(deletePk.pending, (state) => {
        state.pkload = true;
      })
      .addCase(deletePk.fulfilled, (state) => {
        state.pkload = false;
        state.pkError = false;
        state.pkSuccess = true;
      })
      .addCase(deletePk.rejected, (state, action) => {
        state.pkload = false;
        state.pkError = action.payload;
        state.pkSuccess = false;
      });
  },
});

export const { reset } = transactionSlice.actions;
export default transactionSlice.reducer;
