import React from "react";

const Modal = ({ text, handleBack, handleDelete }) => {
  return (
    <div className="p-6 fixed top-[50%] right-[25%] bg-white shadow-lg rounded-md">
      <p>{text}</p>
      <div className="flex gap-4 items-center">
        <button className="bg-red-500 p-2 rounded-md" onClick={handleDelete}>
          Confirm
        </button>
        <button className="bg-blue-500 p-2 rounded-md" onClick={handleBack}>
          Back
        </button>
      </div>
    </div>
  );
};

export default Modal;
