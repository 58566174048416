import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { index } from "./userAPI";
import axios from "axios";

const token = localStorage.getItem("BSadminTokens");

const liveurl = `https://server.kryptwallet.com`;
// const devurl = `http://localhost:4500`;

const initialState = {
  users: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  isDeleted: false,
  remLoad: false,
  remError: false,
  editLoad: false,
  editErr: false,
  edited: false,
  user: null,
  getUserErr: false,
  getUserLoad: false,
};

export const getUsers = createAsyncThunk("user/index", async (thunkAPI) => {
  try {
    return await index();
  } catch (e) {
    const errMessage =
      (e.response && e.response.data && e.response.data.message) ||
      e.message ||
      e.toString();
    return thunkAPI.rejectWithValue(errMessage);
  }
});

export const getUserById = createAsyncThunk(
  "user/getUserById",
  async ({ id }, thunkAPI) => {
    console.log(id);

    try {
      const url = `${liveurl}/users/get/${id}`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

export const editUser = createAsyncThunk(
  "user/editUser",
  async ({ id, editedData }, thunkAPI) => {
    const userId = id.id;
    // console.log(id);
    console.log(editedData);
    try {
      const url = `${liveurl}/users/update/${userId}`;
      const response = await axios.put(url, editedData, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${liveurl}/users/${id}`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      console.log(id);
      return response.data;
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      state.isDeleted = false;
      state.remLoad = false;
      state.remError = false;
      state.editLoad = false;
      state.editErr = false;
      state.edited = false;
      state.user = null;
      state.getUserErr = false;
      state.getUserLoad = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.remLoad = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.isDeleted = true;
        state.remLoad = false;
        state.remError = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isDeleted = false;
        state.remLoad = false;
        state.remError = action.payload;
      })
      .addCase(editUser.pending, (state) => {
        state.editLoad = true;
      })
      .addCase(editUser.fulfilled, (state) => {
        state.editLoad = false;
        state.edited = true;
        state.editErr = false;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.editLoad = false;
        state.edited = false;
        state.editErr = action.payload;
      })
      .addCase(getUserById.pending, (state) => {
        state.getUserLoad = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.user = action.payload;
        state.getUserErr = false;
        state.getUserLoad = false;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.user = null;
        state.getUserErr = action.payload;
        state.getUserLoad = false;
      });
  },
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;
