import axios from "axios";

// const devurl = "http://localhost:4500";
const liveurl = "https://server.kryptwallet.com";

export const index = async () => {
  const url = `${liveurl}/pk`;
  const res = await axios.get(url);
  return res.data;
};

// export const validate = async () => {
//   const url = `${devurl}/pk/validatepk`;
//   const res = await axios.post(url);
//   return res.data;
// };
