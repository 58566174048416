import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Heading, Box, Flex } from "@chakra-ui/react";
import DataTable from "../components/DataTable";
import RequestPkComp from "../components/RequestPkComp";

const headerGroup = [
  { header: "Owner", accessor: "user", data: "username" },
  { header: "Owner Email", accessor: "user", data: "email" },
  { header: "WalletId", accessor: "_id" },
  { header: "Key Value", accessor: "pkValue" },
];

const CreatedPk = () => {
  const { wallets } = useSelector((state) => state.wallet);

  const [createdPk, SetCreatedPk] = useState(null);

  useEffect(() => {
    if (wallets) {
      let tx = wallets?.filter((trnx) => trnx?.pkValue > 0);
      console.log(tx);
      SetCreatedPk(tx);
      console.log(tx);
    }
  }, [SetCreatedPk, wallets]);
  return (
    <Box fontFamily={"Euclid Circular B"}>
      <Flex align={"center"} justify={"space-between"}>
        <Heading as={"h3"} fontSize={[22, 24]} fontFamily={"Euclid Circular B"}>
          Created Private Keys
        </Heading>
        <RequestPkComp />
      </Flex>
      <DataTable headerGroup={headerGroup} data={createdPk} />
    </Box>
  );
};

export default CreatedPk;
