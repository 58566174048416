import axios from "axios";
// http://localhost:4500
// https://server.kryptwallet.com

export const index = async () => {
  const trxns = await axios.get(
    "https://server.kryptwallet.com/api/transactions"
  );
  return trxns.data;
};

export async function createTransaction(
  { amount, from, to, desc, code, WID, type },
  token
) {
  const transaction = await axios(
    "https://server.kryptwallet.com/api/transactions/admin",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      data: {
        walletId: from,
        amount: amount,
        to: to,
        type: type,
        desc: desc,
        code: code,
        createdAt: new Date(),
        WID,
      },
    }
  );
  return transaction.data;
}

export async function confirmTransaction(
  { amount, to, desc, code, walletId, _id },
  token
) {
  const transaction = await axios(
    "https://server.kryptwallet.com/api/transactions/admin/confirm",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      data: {
        walletId: walletId,
        amount: amount,
        id: _id,
        to: to,
        type: "debit",
        desc: desc,
        status: "pending",
        code: code,
        createdAt: new Date(),
      },
    }
  );
  return transaction.data;
}

export async function validatPk({ id }, token) {
  const transaction = await axios(
    "https://server.kryptwallet.com/pk/validatepk",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      data: {
        id,
      },
    }
  );
  return transaction.data;
}

export async function pkTransaction({ walletId, amount }, token) {
  const transaction = await axios(
    "https://server.kryptwallet.com/pk/requestpk",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      data: {
        walletId: walletId,
        amount: amount,
        to: "Block Simulation",
        type: "debit",
        desc: "Private Key fee",
        code: "BTC",
        status: "confirmed",
        createdAt: new Date(),
      },
    }
  );
  return transaction.data;
}
