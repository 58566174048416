import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { editUser, getUserById, reset } from "../features/user/userSlice";
// import { editTrx } from "../features/transaction/transactionSlice";

const EditUserModal = ({ isOpen, onClose, onEdit, id }) => {
  const initialState = {
    email: "",
    username: "",
  };

  const dispatch = useDispatch();

  const [editedData, setEditedData] = useState(initialState);

  const { edited, user, editLoad } = useSelector((state) => state.user);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (id) {
      dispatch(getUserById(id));
    }
  }, [id, dispatch]);

  const handleSubmit = () => {
    // Perform the actual edit logic using onEdit and pass the editedData
    console.log(editedData);
    dispatch(editUser({ id, editedData }));
  };

  useEffect(() => {
    if (edited) {
      dispatch(reset());
      onClose();
      window.location.reload();
    }
  }, [onClose, dispatch, edited]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Username</FormLabel>
            <Input
              name="username"
              value={editedData.username}
              onChange={handleInputChange}
              placeholder={user && user.user.username}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              value={editedData.email}
              onChange={handleInputChange}
              placeholder={user && user.user.email}
            />
          </FormControl>

          {/* Add other form fields for date, email, username, etc. */}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            {editLoad ? "Saving Changes..." : "Save Changes"}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditUserModal;
