import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DataTable from "../components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers, reset } from "../features/user/userSlice";
import EditUserModal from "../components/EditUser";
import Modal from "../components/Modal";

const headerGroup = [
  { header: "Username", accessor: "username" },
  { header: "Email", accessor: "email" },
  { header: "UserId", accessor: "_id" },
  { header: "Status", accessor: "isVerified" },
  { header: "Date Joined", accessor: "createdAt" },
];

// const initialState = {
//   email: "",
//   username: "",
// };

function Users() {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showConfirmDelete, SetShowConfirmDelete] = useState(false);
  const [userId, setUserId] = useState("");

  const { users, isDeleted } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleShowModal = (id) => {
    setIsEditModalOpen(true);
    setUserId(id);
  };

  const handleCloseEditModal = () => {
    // Close the modal
    setIsEditModalOpen(false);
  };

  const showConfirm = (id) => {
    setUserId(id);
    console.log(id);
    SetShowConfirmDelete(true);
  };

  const cancelDelete = () => {
    SetShowConfirmDelete(false);
  };

  // Handling delete user
  const handleDeleteUser = (e) => {
    e.preventDefault();
    // console.log("Delete Clicked", id.id);
    dispatch(deleteUser(userId.id));
  };

  useEffect(() => {
    if (isDeleted) {
      dispatch(reset());
      dispatch(getUsers());
      window.location.reload();
    }
  }, [isDeleted, dispatch]);

  return (
    <Box fontFamily={"Euclid Circular B"}>
      <Heading as={"h3"} fontSize={[22, 24]} fontFamily={"Euclid Circular B"}>
        Users
      </Heading>
      <DataTable
        headerGroup={headerGroup}
        data={users}
        showActions={true}
        onEdit={handleShowModal}
        onDelete={showConfirm}
      />
      {/* Pass the relevant user data to the EditUserModal */}
      <EditUserModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        id={userId}
      />

      {/* confirm delete */}
      {showConfirmDelete && (
        <Modal
          text="Are you sure you want to delete?"
          handleDelete={handleDeleteUser}
          handleBack={cancelDelete}
        />
      )}
    </Box>
  );
}

export default Users;
