import React, { useEffect, useState } from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import DataTable from "../components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePk,
  // getTrnx,
  reset,
  valPk,
} from "../features/transaction/transactionSlice";
import RequestPkComp from "../components/RequestPkComp";
import Modal from "../components/Modal";
import { getAllPrivateKeys } from "../features/pk/pkSlice";

const headerGroup = [
  { header: "TxId", accessor: "_id" },
  { header: "User", accessor: "userFrom", data: "username" },
  // { header: "From", accessor: "walletId" },
  // { header: "to", accessor: "to" },
  { header: "Token", accessor: "code" },
  { header: "Type", accessor: "type" },
  { header: "Amount", accessor: "amount" },
  { header: "Status", accessor: "status" },
  { header: "Date Created", accessor: "createdAt" },
  { header: "Action", accessor: "action" },
];

function RequestPk() {
  const { pkSuccess } = useSelector((state) => state.transaction);
  const { pk } = useSelector((state) => state.pk);
  const { valSuccess } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const [requestPk, setRequestPk] = useState(null);

  const [userId, setUserId] = useState("");
  const [showConfirmDelete, SetShowConfirmDelete] = useState(false);
  const [showConfirmPk, SetShowConfirmPk] = useState(false);

  const confirmPrivateKey = (id) => {
    setUserId(id);
    // console.log(id);
    SetShowConfirmPk(true);
  };

  // Handling delete user
  const handleConfirmPk = (e) => {
    e.preventDefault();
    console.log("Confirm Clicked", userId);
    dispatch(valPk(userId));
  };

  const cancelConfirm = () => {
    SetShowConfirmPk(false);
  };

  const showConfirm = (id) => {
    setUserId(id);
    console.log(id.id);
    SetShowConfirmDelete(true);
  };

  const cancelDelete = () => {
    SetShowConfirmDelete(false);
  };

  // Handling delete user
  const handleDeletePk = (e) => {
    e.preventDefault();
    console.log("Delete Clicked", userId.id);
    dispatch(deletePk(userId.id));
  };

  // console.log("Reuqest", requestPk);

  //get RequestPk
  useEffect(() => {
    if (pk) {
      let tx = pk?.filter((trnx) => trnx?.type === "requestpk");
      console.log(tx);
      setRequestPk(tx);
      console.log(tx);
    }
  }, [pk]);

  // Clean up
  useEffect(() => {
    if (valSuccess) {
      dispatch(reset());
      window.location.reload();
    }
    // dispatch(reset());
  }, [dispatch, valSuccess]);

  //Get pks
  useEffect(() => {
    dispatch(getAllPrivateKeys());
  }, [dispatch]);

  useEffect(() => {
    if (pkSuccess) {
      dispatch(reset());
      // dispatch(getUsers());
      window.location.reload();
    }
  }, [pkSuccess, dispatch]);

  return (
    <Box fontFamily={"Euclid Circular B"}>
      <Flex align={"center"} justify={"space-between"}>
        <Heading as={"h3"} fontSize={[22, 24]} fontFamily={"Euclid Circular B"}>
          Request Of Private Key
        </Heading>

        <RequestPkComp />
      </Flex>
      <DataTable
        headerGroup={headerGroup}
        data={requestPk}
        showActions={true}
        onDelete={showConfirm}
        showPkButton={true}
        onPkConfirm={confirmPrivateKey}
      />

      {/* confirm delete */}
      {showConfirmDelete && (
        <Modal
          text="Are you sure you want to delete?"
          handleDelete={handleDeletePk}
          handleBack={cancelDelete}
        />
      )}
      {showConfirmPk && (
        <Modal
          text="Confirm PK?"
          handleDelete={handleConfirmPk}
          handleBack={cancelConfirm}
        />
      )}
    </Box>
  );
}

export default RequestPk;
