import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { index } from "./pkAPI";

const initialState = {
  pk: null,
  pkLoading: false,
  pkError: false,
  message: "",
};

export const getAllPrivateKeys = createAsyncThunk(
  "pk/getAllPrivateKeys",
  async (thunkAPI) => {
    try {
      return await index();
    } catch (e) {
      const errMessage =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(errMessage);
    }
  }
);

// export const vaidatePrivateKey = createAsyncThunk(
//   "pk/vaidatePrivateKey",
//   async (thunkAPI) => {
//     try {
//       return await validate();
//     } catch (e) {
//       const errMessage =
//         (e.response && e.response.data && e.response.data.message) ||
//         e.message ||
//         e.toString();
//       return thunkAPI.rejectWithValue(errMessage);
//     }
//   }
// );

const pkSlice = createSlice({
  name: "pk",
  initialState,
  reducers: {
    reset(state) {
      state.pk = null;
      state.pkError = false;
      state.pkLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPrivateKeys.pending, (state) => {
        state.pkLoading = true;
      })
      .addCase(getAllPrivateKeys.fulfilled, (state, action) => {
        state.pkLoading = false;
        state.pkError = false;
        state.pk = action.payload;
      })
      .addCase(getAllPrivateKeys.rejected, (state, action) => {
        state.pkLoading = false;
        state.pkError = true;
        state.pk = null;
        state.message = action.payload;
      });
  },
});

export const { reset } = pkSlice.actions;
export default pkSlice.reducer;
