import React, { useEffect, useState } from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import DataTable from "../components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import CreateTransaction from "../components/createTransaction";
import Modal from "../components/Modal";

import {
  // editTrx,
  getTrnx,
  removeTrx,
  reset,
} from "../features/transaction/transactionSlice";
import EditTrxModal from "../components/EditTransaction";
import { useNavigate } from "react-router-dom";

const headerGroup = [
  { header: "TxId", accessor: "_id" },
  { header: "User", accessor: "userFrom", data: "username" },
  { header: "User's Email", accessor: "userFrom", data: "email" },
  // { header: "From", accessor: "walletId" },
  // { header: "to", accessor: "to" },
  { header: "Token", accessor: "code" },
  { header: "Type", accessor: "type" },
  { header: "Amount", accessor: "amount" },
  { header: "Status", accessor: "status" },
  { header: "Date Created", accessor: "createdAt" },
];

function Transactions() {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showConfirmDelete, SetShowConfirmDelete] = useState(false);
  const { transactions, isSuccess, isDeleted } = useSelector(
    (state) => state.transaction
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trnx, setTrnx] = useState(null);

  const [userId, setUserId] = useState("");

  const handleShowModal = (id) => {
    setIsEditModalOpen(true);

    setUserId(id);
    // console.log(id);
  };

  const handleCloseEditModal = () => {
    // Close the modal
    setIsEditModalOpen(false);
  };

  const showConfirm = (id) => {
    setUserId(id);
    console.log(id);
    SetShowConfirmDelete(true);
  };

  const cancelDelete = () => {
    SetShowConfirmDelete(false);
  };

  // Handling delete user
  const handleDeleteTransaction = (e) => {
    e.preventDefault();
    const data = {
      type: userId.type,
      amount: userId.amount,
    };
    console.log("Data", userId);
    console.log("Delete Clicked");
    dispatch(removeTrx({ id: userId.id, formData: data }));
  };

  useEffect(() => {
    let tx = transactions?.filter((trx) => trx?.type !== "requestpk");
    setTrnx(tx);
  }, [transactions]);

  //Clean up
  useEffect(() => {
    dispatch(reset());
  }, [dispatch, isSuccess]);

  //Get Transactions
  useEffect(() => {
    dispatch(getTrnx());
  }, [dispatch]);

  useEffect(() => {
    if (isDeleted) {
      dispatch(reset());
      SetShowConfirmDelete(false);
      window.location.reload();
    }
  }, [isDeleted, SetShowConfirmDelete, dispatch, navigate]);

  return (
    <Box fontFamily={"Euclid Circular B"}>
      <Flex align={"center"} justify={"space-between"}>
        <Heading as={"h3"} fontSize={[22, 24]} fontFamily={"Euclid Circular B"}>
          Transactions
        </Heading>
        <CreateTransaction />
      </Flex>
      <DataTable
        headerGroup={headerGroup}
        data={trnx}
        showActions={true}
        onEdit={handleShowModal}
        onDelete={showConfirm}
      />
      <EditTrxModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        id={userId}
      />

      {/* confirm delete */}
      {showConfirmDelete && (
        <Modal
          text="Are you sure you want to delete?"
          handleDelete={handleDeleteTransaction}
          handleBack={cancelDelete}
        />
      )}
    </Box>
  );
}

export default Transactions;
